@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Regular.ttf')  format('truetype');
}

@font-face {
  font-family: 'RobotoBold';
  src: url('/assets/fonts/Roboto/Roboto-Bold.ttf')  format('truetype');
}

@font-face {
  font-family: 'RobotoBoldItalic';
  src: url('/assets/fonts/Roboto/Roboto-BoldItalic.ttf')  format('truetype');
}

@font-face {
  font-family: 'RobotoItalic';
  src: url('/assets/fonts/Roboto/Roboto-Italic.ttf')  format('truetype');
}

@font-face {
  font-family: 'RobotoMedium';
  src: url('/assets/fonts/Roboto/Roboto-Medium.ttf')  format('truetype');
}

@font-face {
  font-family: 'RobotoMediumItalic';
  src: url('/assets/fonts/Roboto/Roboto-MediumItalic.ttf')  format('truetype');
}