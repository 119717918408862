//variables for social
$social-facebook: #3b5998;
$social-twitter: #55acee;
$social-pinterest: #cc2127;
$social-google: #dd4b39;
$social-linkedin: #0976b4;
$social-dribbble: #ea4c89;
$social-github: #333333;
$social-youtube: #e52d27;
$social-instagram: #125688;
$social-reddit: #ff4500;
$social-tumblr: #35465c;
$social-behance: #1769ff;

$transparent-bg: rgba(200, 200, 200, 0.2) !default;

$heading-color: #464855;
$content-color: #6B6F82;
$background-light-grey: #e8e7e3 !default;
$background-lighter-grey: #f0efeb !default;
$font-background-light-grey: #9c9b99 !default;
$font-hover-background-light-grey: #5e5e5c !default;

$primary: linear-gradient(#111111,#414141);
$primary-text: #2D5296;
$success: #0cc27e;
$info: #1cbcd8;
$warning: #ff8d60;
$danger: #ff586b;
$secondary: #111111;
$light: #f8f9fa;
$dark: #343a40;
$black: #000000;
$body-bg: #f5f7fa;
$white: #ffffff;
$dirtyWhiteDarken: #d9d9d9;
$disabled: #B3B4B5;
$primaryBg: #FEFEFE;
$primaryText: #FEFEFE;
$blue: #1B315A;
$secondaryText: #414141;
$placeholderColor: #888888;
$placeholder50Color: #818284;
$secondaryBg: #eeeeee;
$lightYellow: #F0E2C8;
$strokeColor: #F4F2F2;
$disabledColor: #b8b8b8;
$highlightColor: #e7e7e7;
$divideColor: #eeeeee;
$section: #DFEEFA;
$errorBg: #F3D8DB;
$error: #C73D4B;

$default: $primaryBg;
$group-section: #F6F0DE;
$card-border-color: #e6e6e6;
$section-background-color: #eff6fd;
$header-background-color: #ecf7f6;
$accordion-border-color: #f3f3f3;

$tertiary-stroke-color: #9A9B9D;
$primary-menu-text: #030509;
