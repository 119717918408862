// Font variables
$font-size-base: 14px;

@import "_color.scss";
@import "_font.scss";

main {
  // margin: unset !important;
  // margin-top: 145px !important;
  margin-right: 0px;
  margin-top: 101px;
  .container-fluid {
  }
}
.scroll {
  padding-bottom: 27px;
}
.sub-hidden main {
  margin-left: 227px !important;
}

.main-hidden main {
  margin-left: 0px !important;
}

.pointer {
  cursor: pointer;
}

textarea {
  resize: none;
}

.w-cursor {
  cursor: pointer !important;
}

.no-cursor {
  cursor: default !important;
}

.version-sidebar {
  position: absolute;
  bottom: 0;
  right: 8px;
  left: 8px;
  border-top: 1px solid $disabled;
}

h1 {
  font-family: "RobotoBold";
  font-size: 34px;
}

h2 {
  font-family: "RobotoBold";
  font-size: 28px;
}
h3 {
  font-family: "RobotoBold";
  font-size: 28px;
}
h4 {
  font-family: "RobotoBold";
  font-size: 28px;
}

.h5 {
  font-family: "RobotoBold";
  font-size: 28px;
}
.h6 {
  font-family: "RobotoBold";
  font-size: 28px;
}

.s1 {
  font-family: "RobotoBold";
  font-size: 22px;
}

.s2 {
  font-family: "RobotoBold";
  font-size: 20px;
}

.b1 {
  font-size: 16px;
}

.b2 {
  font-family: "RobotoBold";
  font-size: 16px;
  background-color: unset;
  color: #35373a !important;
  border: 0px;
  &:hover {
    background-color: $section !important;
  }
}

button {
  font-family: "RobotoBold";
  font-size: 16px;
}

.toast {
  font-family: "RobotoMedium";
  font-size: 14px;
}

.placeholder1 {
  font-size: 14px;
}

.placeholder2 {
  font-family: "RobotoBold";
  font-size: 14px;
}

.caption1 {
  font-size: 12px;
}

.caption2 {
  font-family: "RobotoBold";
  font-size: 12px;
}

.form-control {
  background: $primaryBg;
  border-color: $strokeColor;
  // color: $placeholderColor;
  border-radius: 8px;
  padding: 14px, 12px, 14px, 12px;
  font-size: $font-size-base;
}

.btn-primary {
  background: rgb(50, 90, 166) !important;
  background: linear-gradient(180deg, rgba(50, 90, 166, 1) 0%, rgba(27, 49, 90, 1) 65%) !important;

  &:disabled {
    background: rgb(16, 46, 103) !important;
    background: linear-gradient(180deg, rgba(16, 46, 103, 1) 0%, rgba(17, 33, 62, 1) 65%) !important;
  }
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  cursor: pointer;
}

.main-menu {
  .caret {
    flex-grow: 1;
    text-align: right !important;
  }

  .collapse {
    border: 5px;
    // border-color: #B3B4B5;
    border-left: double;
    margin-left: 1.15rem;

    // &:before {
    //   content: " ";
    //   background: #B3B4B5;
    //   border-radius: 10px;
    //   // position: absolute;
    //   width: 6px;
    //   height: 45px;
    //   // top: 50%;
    //   transform: translateY(-50%);
    //   // left: 25;
    // }

    .third-level-menu {
      li {
        flex-grow: 1;
      }
    }
  }

  .active-with-sub {
    background-color: white;
    color: #2d5296;
  }
}

.rt-table {
  border: 3px solid rgba(160, 160, 160, 0.1);
  // border-width: 2px;
  // border-color: $strokeColor;
  border-radius: 12px;
  padding: 2rem;
  font-size: 18px;
  overflow: visible;
  background-color: white;
}

.rt-thead {
  .rt-th {
    font-family: RobotoBold;

    &:last-child {
      text-align: center;
    }
  }
}

.rt-tbody {
  overflow: visible !important;
}

.rt-tr {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.rt-tr-group {
  border-top: 1px solid;
}

.td-action {
  text-align: center;
  overflow: unset !important;

  .btn-group {
    padding-right: 15px;
    .btn {
      padding-top: 6px;
    }
  }
}

.rt-td,
.rt-th {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.custom-dropdown {
  > button {
    padding: 2px 15px !important;
    background-color: white;
    color: $secondary;
    border: 2px solid $primary;
    font-weight: bold;
    color: $primary;
    &:hover {
      background-color: $primary;
      border: 2px solid $primary;
    }
  }
  &:after {
    display: none;
  }
}

.custom-dropdown-menu {
  border-radius: 12px !important;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  button,
  .dropdown-item {
    font-weight: bold;
    color: #4a4a4a !important;
    text-transform: uppercase;
    text-align: left;
  }
}

.dropdown-menu {
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  // border-color: $light !important;
}

.dropdown-item {
  color: #030509 !important;
  &:active {
    color: white !important;
  }
}

.visually-hidden {
  display: none;
}

.dropdown,
.split-dropdown {
}

.dropdown-menu {
  border-radius: 8px;
  border: 1px solid $disabled;
  overflow: hidden;
  padding: 0px;
  .dropdown-item{
    padding: 7px 14px;
    font-size: 12px;
    background-color: unset;
    color: $primary-menu-text !important;
    cursor: pointer;
    font-family: unset !important;
    &:hover {
      color: $primary-text !important;
      background-color: $section;
    }
  }
}

.question-dropdown-menu {
  width: 400px;
  border-radius: 10px;
  min-height: 100px;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;

  .collapse-header {
    padding: 5px;
  }
  .column-btn-delete,
  .btn-delete {
    position: relative;
    right: 10px;
    svg {
      transform: scale(0.7);
    }
  }

  .collapse-header {
    .toggle-arrow {
      position: relative;
      top: 2px;
    }
  }

  .collapse-content {
    padding: 20px 20px;
  }

  .column-dropdown-item {
    border-bottom: 1px solid $divideColor;
  }

  .btn-add-default {
    position: relative;
    top: -1px;
  }
}

.btn-add-default {
  color: $primary-text;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.rt-tr-group {
  border: 0px !important;
  border-bottom: 1px solid #d7d7d7 !important;
}

.simple-icon-options-vertical {
  cursor: pointer;
}

.disabled {
  background-color: #e9ecef !important;
  color: #818284 !important;
}

.nav {
  .nav-item {
    .nav-link.active {
      background-color: $white;
      color: $primary-text;

      &::before {
        top: unset;
        bottom: 0;
        height: 2px;
        border-radius: 0;
        width: 100%;
      }
    }
  }
}

/* BS Modal */
.modal-dialog {
  box-shadow: unset !important;
  > .modal-content {
    border-radius: 10px;

    > .modal-header {
      border: 0px;
      padding-bottom: 10px;
    }

    > .modal-footer {
      display: unset;
      border: 0px;
    }
  }
}

/* BS Card */
// .card {
//   border: 1px solid $card-border-color;
//   box-shadow: unset;

//   .card-header {
//     border-bottom: 1px solid $card-border-color;
//   }

//   .card-body {
//     padding: 0px;
//   }
// }

/* Gogo Collapse */
.collapse-header {
  border-radius: 10px !important;
  border-color: $accordion-border-color !important;
}

/* Nav Items */
.nav {
  .nav-item {
    .nav-link.active {
      background-color: $white;
      color: $primary-text;

      &::before {
        top: unset;
        bottom: 0;
        height: 2px;
        border-radius: 0;
        width: 100%;
      }
    }
  }
}

.btn-qb-danger {
  background: linear-gradient(to bottom, #C73D4B, #881A25);
  color: white;
}

.btn-qb-outline-danger {
  color: #C73D4B;
  background-color: white;
  border-width: 2px;
  border-color: #C73D4B;
}
