@import '@assets/css/sass/custom/_color.scss';
.confirm-modal-content-style {
  border-radius: 8px !important;
}

.confirm-modal-style {

}

.confirm-modal-body-style {

}

.confirm-modal-header-style {
  color: $primary;
  border-bottom: 0px !important;
}

.confirm-modal-header-style .close {
  font-size: 30px !important;
  color: $default !important;
}

.confirm-modal-footer-style {
  padding: 1.75rem !important;
  border-top: 0px !important;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.confirm-modal-cancel-button {
  border: none !important;
  box-shadow: none !important;
  color: #707070;

  &:hover {
    color: $primary !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
}