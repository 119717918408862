@import './assets/css/vendor/bootstrap.min.css';
@import './assets/css/vendor/bootstrap.rtl.only.min.css';
@import '~react-circular-progressbar/dist/styles.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
// @import '~react-big-calendar/lib/css/react-big-calendar.css';
@import '~react-table/react-table.css';
@import './assets/css/sass/themes/gogo.light.blue.scss';
@import './assets/css/sass/custom/_system-wide.scss';
// @import '../node_modules/react-redux-toastr/src/styles/index';
// @import '~rc-switch/assets/index.css';

.ReactTable {
  .rt-table, .rt-tbody, .rt-th, .rt-td {
    overflow: visible !important;
  }
}

iframe{
  display: none;
}

.preview-modal{
  max-width: 1000px;
  .modal-body{
    border: 1px solid whitesmoke;
  }
  iframe{
    display: block;
    width: 100%;
    border:0px
  }
}

.question-condition-modal{
  max-width: 1000px;
}
.accordion {
  .accordion-item{
    .accordion-header{
      .accordion-button::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        content: "";
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
        position: absolute;
        right: 10px;
      }

      .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        transform: rotate(-180deg);
      }
    }
  }
}
