.loading{
    .container .row{
        height:500px;
    }
}

.full-screen{
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1100;
    background: rgba(0,0,0,0.6);
    .container{
        height: 100%;
    }
    .row{
        padding-top:25%;
    }

    .spinner{
        margin:auto !important;
        width: 50px;
        height: 45px;
        > div{
            width:8px;
        }
    }
}
