@import "@assets/css/sass/custom/_color.scss";

.floating-label {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 1;
  pointer-events: none;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.float {
  bottom: auto;
  top: -7px;
  background-color: white;
  padding-left: 3px;
  padding-right: 3px;
  left: 11px;
  font-size: 0.7rem;
  z-index: 1;
  color: $disabledColor;
}

.form-select {
  &__control {
    border-radius: 10px !important;
  }

  &__menu {
    z-index: 2 !important;
  }
}